
import axios from "axios"
import { CONST } from "config"

export const startWithOAuth2 = (providerUrl) => {

    let timer = null
    const authWindow = window.open(providerUrl)

    return new Promise((resolve, reject) => {
        timer = setInterval(() => {
            if (authWindow && authWindow.closed) {
                fetchUser().then((response) => {
                    resolve(response)
                }).catch((error) => {
                    console.error(error)
                    reject(error)
                })
                timer && clearInterval(timer)
            }
        }, 500)
    })

    function fetchUser() {
        return axios.get(CONST.uri.auth.GET_USER_SESSION, { withCredentials: true })
    }
}
