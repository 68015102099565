
import { env } from "./config"

export const uri = {
    auth: {
        GITHUB_LOGIN: env.API_SERVER + "/login/github",
        GET_USER_SESSION: env.API_SERVER + "/oauth/user",
    },
    resources: {
        USERS: env.API_SERVER + "/users/"
    },
    services: {
        OAUTH2_GITHUB_STATUS: env.API_SERVER + "/login/github/status"
    }
}