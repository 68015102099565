import { useEffect, useState } from 'react'

import { GitHubOAuth2Button } from 'components/buttons'
import { CONST } from "config"
import { useNavigate } from 'react-router-dom'
import { authController, serviceController } from "services/http"

function Separator() {
    return (
        <div className='or-container my-4'>
            <p className='or-divider'>
                or
            </p>
        </div>
    )
}


function LoginPage({ handleLogin }) {

    let navigate = useNavigate()

    const [isGithubAvailable, setIsGithubAvailable] = useState(false)

    useEffect(() => {
        let isUserAuthenticated = localStorage.getItem("sid")
        if (isUserAuthenticated) {
            handleLogin()
            return navigate("/home")
        }

        serviceController.isOAuth2GithubAvailable()
            .then(response => {
                let { serviceName, isActive } = response.data
                console.log(`${serviceName} status: ${isActive ? "Available" : "Not available"}`)
                setIsGithubAvailable(isActive)
            })

    }, [])


    const [messageError, setMessageError] = useState("")

    const startWithGitHub = function (e) {
        e.preventDefault()
        authController.startWithOAuth2(CONST.uri.auth.GITHUB_LOGIN)
            .then(onSuccessLogin)
            .catch(onFailLogin)
    }

    const onSuccessLogin = function ({ data }) {
        let { sid } = data

        if (!sid) {
            let error = "An error occurred during the login process"
            console.log(error)
            setMessageError(error)
            return
        }
        localStorage.setItem("sid", JSON.stringify(sid))
        handleLogin()
        navigate("/home")
    }

    const onFailLogin = function (error) {
        if (typeof error !== "object" && !error.response?.data) {
            return
        }
        error = error.response.data.error
        console.log(error)
        setMessageError(error)
    }

    return (
        <section className='login-card'>

            <h2 className='mb-3'>Log in</h2>

            {isGithubAvailable && <GitHubOAuth2Button onClick={startWithGitHub} />}

            {(isGithubAvailable) && <Separator />}
        </section>
    )
}


export default LoginPage